import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        hero_juliamatt: file(
          sourceInstanceName: { eq: "hero" }
          name: { eq: "julia-matt2" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper className={`section hero is-medium has-background`}>
        <BackgroundImage>
          <Img fluid={data.hero_juliamatt.childImageSharp.fluid} />
        </BackgroundImage>
        <div class="hero-body">
          <div class="container">
            <h1 class="title has-text-white">
              Julia & Matthew
            </h1>
            <h2 class="subtitle has-text-white">May 1 2021, Key West Florida.</h2>
          </div>
        </div>
      </HeaderWrapper>
    )}
  />
);


const HeaderWrapper = styled.section`
  position: relative;
  overflow: hidden;
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 56px;
`;

const Art = styled.figure`
  width: 100%;
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.color.black.regular};
  }
`;

export default Header;
