import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { Section } from '@components/global';

const Details = () => (
  <StaticQuery
    query={graphql`
      query {


        ceremony_argo: file(
          sourceInstanceName: { eq: "ceremony" }
          name: { eq: "argo" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        reception_firstflight: file(
          sourceInstanceName: { eq: "reception" }
          name: { eq: "firstflight" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section className={`section`} id="details" accent>
        <div class="container">
          <div class="columns">
            <div class="column">
              <h2 class="is-size-2">Reservations and Parking</h2>
              <p>Although we do not have a room block for the wedding, we recommend checking both traditional hotels and AirBNB options for your stay in Key West. Many hotels offer parking for guests, but you may also be able to find free parking on the residential streets in central Key West. If you’re staying downtown near the wedding events, you should be able to walk or take the local trolley to all the local hot spots.</p>
            </div>
            <div class="column">
              {/* <h2 class="is-size-2">Wedding Registry</h2>
                <p>As we begin our lives together, we are especially grateful for support from our loved ones. We recognize how lucky we are to be blessed with the necessities of everyday life, and have decided to instead plan an unforgettable honeymoon to ______ to celebrate the beginning of our married life together. If you’d like to help us along on our next adventure you can contribute to our honeymoon fund below.</p> */}
            </div>
          </div>
        </div>
      </Section>
    )}
  />
);

export default Details;
