import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


const About = () => (
  <StaticQuery
    query={graphql`
      query {


        ceremony_argo: file(
          sourceInstanceName: { eq: "ceremony" }
          name: { eq: "argo" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        reception_firstflight: file(
          sourceInstanceName: { eq: "reception" }
          name: { eq: "firstflight" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
        <section class="section" id="wedding">
          <div class="container">
            <h2 class="is-size-2">Ceremony</h2>
            <div class="columns">
              <div class="column">
                  <p>The ceremony will take place on Saturday, May 1 2021 aboard The Argo Navis in Key West Florida.  The boat sets sail at 5:00 pm EST, so make sure to be there approximately 30 minutes in advance.  The ceremony will include a cocktail hour.  The Argo Navis is located at Bight Marina, 0 Margaret Street, Key West FL.</p>
                  <br/>
                  <h2>Time/Date</h2>
                  <p>5:00 - 7:00 pm ET, Saturday, May 1 2021</p>
                  <p><a  href="https://www.google.com/calendar/render?action=TEMPLATE&text=Julia+%26+Matthew%27s+Wedding&location=Bight+Marina+0%2C+Margaret+St%2C+Key+West%2C+FL+33040&dates=20200606T203000Z%2F20200607T020000Z">Add to calendar (android)</a></p>
                  <br/>
                  <h2>Location</h2>
                  <p>0 Margaret St, Key West, FL 33040</p>
                  <p><a href="https://www.google.com/maps/dir/?api=1&destination=bight+marina+0+margaret+street+key+west+florida+33040">Click for directions</a></p>
              </div>
              <div class="column">
                <Art>
                  <Img fluid={data.ceremony_argo.childImageSharp.fluid} />
                </Art>
              </div>
            </div>
            <h2 class="is-size-2">Reception</h2>
            <div class="columns">
              <div class="column">
                <p>The reception will be held at the First Flight Island Restaurant and Brewery starting at 7:30. Join us upstairs in the Tree-Top Deck for dinner, drinks and dancing!</p>
                <br/>
                <h2>Time/Date</h2>
                <p>7:00 - 10:00 pm ET, Saturday, May 1 2021</p>
                <p><a  href="https://www.google.com/calendar/render?action=TEMPLATE&text=Julia+%26+Matthew%27s+Wedding&location=Bight+Marina+0%2C+Margaret+St%2C+Key+West%2C+FL+33040&dates=20200606T203000Z%2F20200607T020000Z">Add to calendar (android)</a></p>
                <br/>
                <h2>Location</h2>
                <p>0 Margaret St, Key West, FL 33040</p>
                <p><a href="https://www.google.com/maps/dir/?api=1&destination=301+whitehead+street+key+west+florida+33040">Click for directions</a></p>
              </div>
              <div class="column">
                <Art>
                  <Img fluid={data.reception_firstflight.childImageSharp.fluid} />
                </Art>
              </div>
            </div>
          </div>
        </section>
    )}
  />
);

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
